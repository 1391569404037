import React from 'react';
import { Router as BrowserRouter } from '@reach/router';


const Router = () => {

    return(
        <BrowserRouter>
        </BrowserRouter>
    )
}

export default Router;